/**
 * @generated SignedSource<<5a21e881794b6291e44d87ca86d70792>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomDomainVerificationStatus = "PENDING" | "VERIFIED" | "%future added value";
export type OrganizationMemberRole = "ADMIN" | "MEMBER" | "OWNER" | "%future added value";
export type OrganizationStatus = "ACTIVE" | "ARCHIVED" | "DRAFT" | "SUSPENDED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type AdminAuthContext_authState$data = {
  readonly viewer: {
    readonly __typename: "User";
    readonly canCreateOrganization: boolean;
    readonly email: string;
    readonly givenName: string | null | undefined;
    readonly id: string;
    readonly image: string | null | undefined;
    readonly lastName: string | null | undefined;
    readonly normalizedEmail: string;
    readonly organizationsAsInstructor: ReadonlyArray<{
      readonly id: string;
      readonly image: string | null | undefined;
      readonly name: string;
      readonly slug: string;
      readonly status: OrganizationStatus;
      readonly websiteSettings: {
        readonly customDomain: {
          readonly domain: string;
          readonly verificationStatus: CustomDomainVerificationStatus;
        } | null | undefined;
      } | null | undefined;
    }>;
    readonly organizationsAsMember: ReadonlyArray<{
      readonly organization: {
        readonly id: string;
        readonly image: string | null | undefined;
        readonly name: string;
        readonly slug: string;
        readonly status: OrganizationStatus;
        readonly websiteSettings: {
          readonly customDomain: {
            readonly domain: string;
            readonly verificationStatus: CustomDomainVerificationStatus;
          } | null | undefined;
        } | null | undefined;
      };
      readonly role: OrganizationMemberRole;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentType": "AdminAuthContext_authState";
};
export type AdminAuthContext_authState$key = {
  readonly " $data"?: AdminAuthContext_authState$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminAuthContext_authState">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": 360
    }
  ],
  "kind": "ScalarField",
  "name": "image",
  "storageKey": "image(size:360)"
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "WebsiteSettings",
    "kind": "LinkedField",
    "name": "websiteSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomDomain",
        "kind": "LinkedField",
        "name": "customDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verificationStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminAuthContext_authState",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "normalizedEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "givenName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canCreateOrganization",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationMember",
              "kind": "LinkedField",
              "name": "organizationsAsMember",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "excludeOrganizationsAsMember",
                  "value": true
                }
              ],
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organizationsAsInstructor",
              "plural": true,
              "selections": (v2/*: any*/),
              "storageKey": "organizationsAsInstructor(excludeOrganizationsAsMember:true)"
            }
          ],
          "type": "User",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7ca11cf7bf6ae4e17efc1b1567947cd3";

export default node;
