"use client";

import { useAdminViewer } from "@/admin/context/AdminAuthContext";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";
import useLogout from "@/auth/hooks/useLogout";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import PiLogo from "@/components/PiLogo";
import Link from "next/link";

const NavBar: React.FC<{
  disableContainer?: boolean;
  startIcon?: React.ReactNode;
}> = ({ disableContainer = false, startIcon = null }) => {
  const viewer = useAdminViewer();

  return (
    <header className="supports-backdrop-blur:bg-background/60 sticky top-0 z-dialog w-full border-b bg-background/95 px-4 backdrop-blur sm:px-6">
      <div
        className={cn("flex h-16 items-center", {
          container: !disableContainer,
        })}
      >
        {startIcon}
        {/* TODO: admin içinde ne olacak (subdomain/custom domain'de admin) */}
        <Link href="/" className="mr-2">
          <PiLogo className="size-8 text-primary" />
        </Link>
        <span className="flex-1" />
        {viewer ? (
          <NavBarViewer viewer={viewer} />
        ) : (
          <>
            <Button asChild variant="link">
              <Link href="/pricing">Fiyatlar</Link>
            </Button>
            <Button asChild>
              <Link href="/kaydol">Hemen Başla</Link>
            </Button>
          </>
        )}
      </div>
    </header>
  );
};

const NavBarViewer: React.FC<{
  viewer: NonNullable<ReturnType<typeof useAdminViewer>>;
}> = ({ viewer }) => {
  const fullName = [viewer.givenName, viewer.lastName]
    .filter(Boolean)
    .join(" ");

  const initials = [viewer.givenName, viewer.lastName]
    .filter(Boolean)
    .map((name) => name?.[0])
    .join("");

  const [logout, isLogoutInFlight] = useLogout();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative size-8 rounded-full">
          <Avatar className="size-8">
            <AvatarImage src={viewer.image ?? undefined} alt={fullName} />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{fullName}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {viewer.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {viewer.organizationsAsMember.map(({ organization }) => (
          <NavBarViewerOrganization
            key={organization.id}
            organization={organization}
            type="member"
          />
        ))}
        {viewer.organizationsAsInstructor.map((organization) => (
          <NavBarViewerOrganization
            key={organization.id}
            organization={organization}
            type="instructor"
          />
        ))}
        {viewer.canCreateOrganization ? (
          <>
            <DropdownMenuItem asChild>
              {/* TODO: admin içinde ne olacak (subdomain/custom domain'de admin) */}
              <Link href="/kaydol">Organizasyon Oluştur</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        ) : null}
        <DropdownMenuItem
          onClick={() => {
            logout();
          }}
        >
          Oturumu Kapat
          <DropdownMenuShortcut className="hidden">⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const NavBarViewerOrganization: React.FC<{
  organization: NonNullable<
    NonNullable<
      ReturnType<typeof useAdminViewer>
    >["organizationsAsMember"][number]
  >["organization"];
  type: "member" | "instructor";
}> = ({ organization, type }) => {
  const initials = organization.name
    .split(" ")
    .map((word) => word[0])
    .join("");

  return (
    <>
      <DropdownMenuGroup>
        <DropdownMenuItem key={organization.id} className="block" asChild>
          <Link
            href={
              type === "member"
                ? `/${organization.slug}/admin`
                : `/${organization.slug}/admin/courses`
            }
          >
            <div className="flex">
              <Avatar className="size-8 rounded-lg">
                <AvatarImage
                  src={organization.image ?? undefined}
                  alt={organization.name}
                />
                <AvatarFallback>{initials}</AvatarFallback>
              </Avatar>
              <div className="flex flex-col space-y-1 pl-2">
                <p className="text-sm font-medium leading-none">
                  {organization.name}
                </p>
                <p className="text-xs leading-none text-muted-foreground">
                  {organization.status}
                </p>
              </div>
            </div>
            <p className="mt-2">
              {type === "member" ? "Yönetici Paneli" : "Derslerim"}
            </p>
          </Link>
        </DropdownMenuItem>
        {type === "member" ? (
          <DropdownMenuItem asChild>
            <Link
              target="_blank"
              href={
                organization.websiteSettings?.customDomain &&
                organization.websiteSettings.customDomain.verificationStatus ===
                  "VERIFIED"
                  ? `//${organization.websiteSettings?.customDomain.domain}`
                  : `https://${organization.slug}.pi.education`
              }
            >
              Öğrenci Ana Sayfası
              <DropdownMenuShortcut>
                <ExternalLinkIcon className="size-4" />
              </DropdownMenuShortcut>
            </Link>
          </DropdownMenuItem>
        ) : null}
      </DropdownMenuGroup>
      <DropdownMenuSeparator />
    </>
  );
};

export default NavBar;
