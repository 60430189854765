"use client";

import { graphql, useFragment } from "react-relay";
import { createContext, useContext } from "react";
import {
  AdminAuthContext_authState$data,
  AdminAuthContext_authState$key,
} from "@generated/AdminAuthContext_authState.graphql";

interface Props {
  authState: AdminAuthContext_authState$key;
}

const AdminAuthContext = createContext<AdminAuthContext_authState$data | null>(
  null,
);

const AdminAuthContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  authState,
}) => {
  const data = useFragment(
    graphql`
      fragment AdminAuthContext_authState on Query {
        viewer {
          __typename
          ... on User {
            id
            email
            normalizedEmail
            givenName
            lastName
            canCreateOrganization
            image(size: 360)
            organizationsAsMember {
              role
              organization {
                id
                slug
                name
                status
                image(size: 360)
                websiteSettings {
                  customDomain {
                    domain
                    verificationStatus
                  }
                }
              }
            }
            organizationsAsInstructor(excludeOrganizationsAsMember: true) {
              id
              slug
              name
              status
              image(size: 360)
              websiteSettings {
                customDomain {
                  domain
                  verificationStatus
                }
              }
            }
          }
        }
      }
    `,
    authState,
  );

  return (
    <AdminAuthContext.Provider
      value={data}
      key={
        data.viewer
          ? "id" in data.viewer
            ? data.viewer.id
            : undefined
          : undefined
      }
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminViewer = () => {
  const context = useContext(AdminAuthContext);

  if (!context) {
    throw new Error("No auth data set, use AuthStoreProvider to set one");
  }

  return context.viewer?.__typename === "User" ? context.viewer : null;
};

export default AdminAuthContextProvider;
